/* eslint-disable */
import {API} from 'aws-amplify';
import { HttpError } from 'react-admin';

const dataProvider = {
  getList: async (resource, params) => {
    const {pagination, filter, sort} = params;
    pagination.per_page = pagination.perPage;
    delete pagination.perPage;
    Object.keys(filter).map((filterKey) => {
      const filterValue = filter[filterKey];
      filter[filterKey] = Array.isArray(filterValue) ? filterValue.join() : filterValue;
    })
    if ('created_from' in filter) {
      const createdFrom = new Date(filter['created_from'])
      const now = new Date()
      filter['created_from'] = new Date(
        createdFrom.setMinutes(createdFrom.getMinutes() + now.getTimezoneOffset() * -1)).toISOString()
    }
    if ('created_to' in filter) {
      const createdTo = new Date(filter['created_to'])
      const now = new Date()
      filter['created_to'] = new Date(
        createdTo.setMinutes(createdTo.getMinutes() + now.getTimezoneOffset() * -1)).toISOString()
    }
    if (Object.keys(filter).length === 0) {
      return {data: [{id: 0}], total: 0}
    }

    const init = {
      queryStringParameters: {
        ...pagination,
        ...filter,
        ...sort
      },
    };

    let response;
    try {
      response = await API.get('admin', `/${resource}`, init);
    } catch(e) {
      const errorMessage = e.response.data?.error;

      if(errorMessage) {
        throw new HttpError(errorMessage);
      } else {
        throw e;
      }
    }
    const {items = null, _meta = null} = response
    return {data: items ? items : response, total: _meta ? _meta.total_items : response.length};
  },
  getOne: async (resource, params) => {
    const {id} = params;

    const data = await API.get('admin', `/${resource}/${id}`, null);

    return {data};
  },
  create: async (resource, params) => {
    const {data: payload} = params;
    const response = await API.post('admin', `/admin/${resource}`, {body: payload});

    return {data: {...response}};
  },
  update: async (resource, params) => {
    const {id, data} = params;
    const response = await API.put('admin', `/${resource}/${id}`, {body: data});
    return {data: response};
  },
  delete: async (resource, params) => {
    const {id} = params;
    let response = null;
    if (id) {
      try {
        response = await API.del('admin', `/${resource}/${id}`, null);

      } catch (err) {
        const {data: {error}} = err.response;

        throw new Error(error);
      }
    }

    return {data: {...response}};
  },
  export: async (resource, params) => {
    const response = await API.get('admin', `/${resource}/export`, {queryStringParameters: params});

    return {data: response};
  },
  downloadOrderTemplate: async (params) => {
    const response = await API.get('admin', `/orders/download`, {queryStringParameters: params});
    return {data: response.data};
  }
};

export default dataProvider;
